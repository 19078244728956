export const optionsTableRewards = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 25,
	rowsPerPageOptions: [25, 50, 100],
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
};

export const optionsOrderHistory = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 5,
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
	selectableRows: 'none',
};
