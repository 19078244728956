import React from 'react';
import { Typography, Box } from '@mui/material';
import { MainBox } from './utils/themes';
import ListRewardCards from './components/RewardCards/ListRewardCards';
import useRewardCards from './hooks/useRewardCards';
import Search from './components/RewardCards/SearchRewardCards';
import ExportCSV from './components/RewardCards/ExportRewardCards';

const RewardCards = () => {
	// Get the order cards records
	const { orderCardsRecords, setFilter } = useRewardCards();

	return (
		<MainBox>
			<Typography variant="h4">Reward Cards</Typography>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				my={2}
			>
				<Search setFilter={setFilter} />
				<ExportCSV data={orderCardsRecords} />
			</Box>
			<ListRewardCards orderCardsRecords={orderCardsRecords} />
		</MainBox>
	);
};
export default RewardCards;
